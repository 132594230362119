import './index.css';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import MyRoutes from '@/router';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <BrowserRouter basename={import.meta.env.VITE_PATH as string}>
    <MyRoutes />
  </BrowserRouter>,
);
