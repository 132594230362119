/*
 * 省市区组件
 * @param width 宽度
 * @param disabled 是否禁用
 * */
import { Cascader } from 'antd';
import { useEffect, useState } from 'react';

import { getProvince } from '@/network/api/api';

const Province = ({ width, value, onChange, disabled }: any) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    // 在此可以执行任何带副作用操作
    getProvince({}).then(({ data }: any) => {
      data?.map((e: any) => (e.isLeaf = false));
      setOptions(data);
    });
    return () => {};
  }, []);

  // 给父级传数据
  const handleProviceData = (_: any, selectedOptions: any) => onChange(selectedOptions);
  const loadData = (selectedOptions: any) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    getProvince({ id: targetOption.addressId }).then(({ data }: any) => {
      targetOption.loading = false;
      data.forEach((e: any) => {
        // 区没有下一级，是叶子
        e.isLeaf = selectedOptions.length === 2;
      });
      targetOption.children = data;
      setOptions([...options]);
    });
  };
  return (
    <Cascader
      style={{ width: width }}
      defaultValue={value} //默认值由外部传进来
      // value={value} // TODO:什么时候用defaultValue?什么时候用value设置默认值?
      options={options}
      disabled={disabled}
      onChange={handleProviceData}
      fieldNames={{ label: 'addressName', value: 'addressId', children: 'children' }}
      loadData={loadData}
      changeOnSelect
      placeholder="请选择省市区"
    />
  );
};

export default Province;
