import 'rc-texty/assets/index.css';
import './index.less';
// 资源强引用
import '@assets/font/Impact.ttf';
import '@assets/font/PangMenZhengDao.ttf';
import '@assets/page_5_wrap_bg.png';
import '@assets/scenic/1.png';
import '@assets/scenic/2.png';
import '@assets/scenic/3.png';
import '@assets/scenic/4.png';
import '@assets/scenic/5.png';
import '@assets/scenic/6.png';
import '@assets/scenic/7.png';
import '@assets/scenic/8.png';

import { MenuOutlined } from '@ant-design/icons';
import $header_logo_white from '@assets/header_logo_white.png';
import $page_1_bg from '@assets/page_1_bg.png';
import $page_2_bg_1 from '@assets/page_2_bg_1.png';
import $page_2_bg_2 from '@assets/page_2_bg_2.png';
import $page_2_bg_3 from '@assets/page_2_bg_3.png';
import $page_2_icon_1 from '@assets/page_2_icon_1.png';
import $page_2_icon_2 from '@assets/page_2_icon_2.png';
import $page_2_icon_3 from '@assets/page_2_icon_3.png';
import $page_2_icon_4 from '@assets/page_2_icon_4.png';
import $page_2_icon_5 from '@assets/page_2_icon_5.png';
import $page_2_icon_6 from '@assets/page_2_icon_6.png';
import $page_2_icon_7 from '@assets/page_2_icon_7.png';
import $page_2_icon_8 from '@assets/page_2_icon_8.png';
import $page_2_icon_9 from '@assets/page_2_icon_9.png';
import $page_3_content_bg from '@assets/page_3_content_bg.png';
import $page_4_bg from '@assets/page_4_bg.png';
import $page_4_content_bg from '@assets/page_4_content_bg.png';
import $page_5_bottom_icon_1 from '@assets/page_5_bottom_icon_1.png';
import $page_5_bottom_icon_2 from '@assets/page_5_bottom_icon_2.png';
import $page_5_bottom_icon_3 from '@assets/page_5_bottom_icon_3.png';
import $page_5_bottom_icon_4 from '@assets/page_5_bottom_icon_4.png';
import $page_5_icon_1 from '@assets/page_5_icon_1.png';
import $page_5_icon_2 from '@assets/page_5_icon_2.png';
import $page_5_icon_3 from '@assets/page_5_icon_3.png';
import $page_5_icon_4 from '@assets/page_5_icon_4.png';
import $page_6_bg from '@assets/page_6_bg.png';
import { Dropdown } from 'antd';
import QueueAnim from 'rc-queue-anim';
import { OverPack } from 'rc-scroll-anim';
import Texty from 'rc-texty';
import TweenOne from 'rc-tween-one';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

TweenOne.plugins.push(Children);
const numList = [
  {
    value: 30,
    title: '景区云平台',
    tips: '服务景区（B端）',
  },
  {
    value: 10,
    unit: '亿',
    title: '电商云平台',
    tips: '交易规模（GMV）',
  },
  {
    value: 20,
    title: '监管云平台',
    tips: '服务政府（G端）',
  },
];
// 路由慧旅云
const goToWelcome = () => {
  location.href += 'welcome';
  location.reload();
};
const scenicList = [
  '剑门关风景区',
  '米仓山国家森林公园',
  '某森林生态系统国家自然保护区',
  '某山脉风景区',
  '某花湖风景名胜区',
  '唐家河自然保护区',
  '某国家森林公园',
  '美姑大风顶自然保护区',
];
const navList = ['行业痛点', '客户需求', '产品体验', '合作景区'];
const iconList = [
  {
    title: '数金云',
    tips: '聚合主流支付方式，融合区块链技术，适用多种支付场景，满足多样收款、统一对账、空中分账、引流营销、合规监管等不同需求',
    icon: [
      {
        url: $page_2_icon_1,
        name: '交易结算',
      },
      {
        url: $page_2_icon_2,
        name: '聚合支付',
      },
      {
        url: $page_2_icon_3,
        name: '跨境付款',
      },
    ],
    bg: $page_2_bg_1,
  },
  {
    title: '数科云',
    tips: '涵盖基础技术、算法、模型及数据应用等通用能力，基于多场景累积的大数据实践经验，为客户提供有效、可靠的业务保障',
    icon: [
      {
        url: $page_2_icon_4,
        name: '身份认证',
      },
      {
        url: $page_2_icon_5,
        name: '数据治理',
      },
      {
        url: $page_2_icon_6,
        name: '算法模型',
      },
    ],
    bg: $page_2_bg_2,
  },
  {
    title: '元宇宙应用',
    tips: '以区块链、联盟链、NFT技术为核心，自主研发、安全可控、符合国密标准，NFT管理，数字藏品、门票上链等功能已上线 ',
    icon: [
      {
        url: $page_2_icon_7,
        name: '数智人',
      },
      {
        url: $page_2_icon_8,
        name: '区块链',
      },
      {
        url: $page_2_icon_9,
        name: '数字孪生',
      },
    ],
    bg: $page_2_bg_3,
  },
];
let timeId: NodeJS.Timeout;
let i = 0;

const Home = () => {
  const navigate = useNavigate();
  const wrapList = [
    {
      title: '景区运营商',
      content: ['景区综合管控', '智慧票务管理', '销售订单管理', '营销服务管理'],
      icon: $page_5_icon_1,
      bg: $page_5_bottom_icon_1,
      regist: () => navigate('/form?type=0'),
    },
    {
      title: '企业分销商',
      content: ['分销拓展销售渠道', '组合套餐产品管理', '分销团队管理', '智能数据分析'],
      icon: $page_5_icon_2,
      bg: $page_5_bottom_icon_2,
      // regist: () => (location.href = import.meta.env.VITE_URL_EXCHANGE || ''),
      regist: () => navigate('/form?type=1'),
    },
    {
      title: '个人代理商',
      content: ['低门槛全员代理', '3分钟快速开店', '佣金实时查看', '私域快速转化'],
      icon: $page_5_icon_3,
      bg: $page_5_bottom_icon_3,
      regist: () => (location.href = import.meta.env.VITE_URL_EXCHANGE || ''),
    },
    {
      title: '政旅云',
      content: ['发布景区资讯', '聚合景区应用', '数字门票查询', '投诉建议反债'],
      icon: $page_5_icon_4,
      bg: $page_5_bottom_icon_4,
      regist: () => (location.href = 'https://prod.shukeyun.com/data/dashboard/#/?id=zly_01_01'),
    },
  ];
  const [overIndex, setOverIndex] = useState(0);
  const [mode, setMode] = useState('enter');
  const [mode5, setMode5] = useState('enter');
  // 鼠标进入景区
  const mouseOver = (e: number) => {
    clearInterval(timeId);
    setOverIndex(e);
    i = e;
  };
  // 鼠标离开景区
  const mouseLeave = () => {
    timeId = timeFun();
  };
  // 景区定时轮播
  const timeFun = () =>
    setInterval(() => {
      i = i < 7 ? i + 1 : 0;
      setOverIndex(i);
    }, 2000);
  // 初始化
  useEffect(() => {
    timeId = timeFun();
  }, []);

  // 首屏动画
  useLayoutEffect(() => {
    const content: number = document.querySelector('.page-1 .content')?.clientHeight || 0;
    new MutationObserver(() => {
      const contentNow: number = document.querySelector('.page-1 .content')?.clientHeight || 0;
      const height: number =
        (document.querySelector('.num-wrap')?.clientHeight || 0) / 2 - 85 - (contentNow - content) / 2;
      const dom: Element = document.querySelector('.title-wrap') as Element;
      dom.setAttribute('style', `transform: translateY(${height}px);`);
      setTimeout(() => {
        dom.setAttribute('style', `transform: translateY(-50px);transition: .3s ease-in-out;`);
      }, 0);
      setTimeout(() => {
        dom.setAttribute('style', `transform: translateY(0);transition: .3s ease-in-out;`);
      }, 300);
    }).observe(document.querySelector('.num-wrap') as Element, { childList: true });
  }, []);
  // 定位锚点
  const toView = (e: number) => {
    document.querySelector(`.page-${e + 3}`)?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="home">
      <div className="header">
        <div className="content">
          <img className="logo" src={$header_logo_white} onClick={goToWelcome} />
          <div className="menu-pc">
            {navList.map((v, k) => (
              <span key={k} onClick={() => toView(k)}>
                {v}
              </span>
            ))}
          </div>
          <Dropdown
            menu={{
              items: navList.map((label, key) => ({ key, label })),
              onClick: ({ key }) => toView(+key),
            }}
            placement="bottom"
            arrow
          >
            <MenuOutlined className="menu-app" />
          </Dropdown>
        </div>
      </div>
      <div className="page page-1" style={{ background: `url(${$page_1_bg}) no-repeat center/cover` }}>
        <div className="content">
          <div className="title-wrap">
            <Texty className="title" type="scale" interval={20}>
              WHAT&nbsp;&nbsp;WE&nbsp;&nbsp;DO
            </Texty>
            <Texty className="tips" delay={1000} duration={80} interval={20}>
              以核心云平台驱动数字化新基建，打造国内引领的智慧行业解决方案。以区块链应用筑牢可信数字底座，打造技术融合的全场景智慧应用体系
            </Texty>
          </div>
          <QueueAnim className="num-wrap" delay={2300} duration={500} type="scaleBig">
            <div key="key" className="num">
              {numList.map((v) => (
                <div key={v.value}>
                  <span>
                    <TweenOne
                      animation={{
                        Children: {
                          value: v.value,
                          floatLength: 0,
                        },
                        duration: 1000,
                      }}
                    >
                      0
                    </TweenOne>
                    {v.unit && <span>{v.unit}</span>}
                    <span>+</span>
                  </span>
                  <span>{v.title}</span>
                  <span>{v.tips}</span>
                </div>
              ))}
            </div>
          </QueueAnim>
        </div>
      </div>
      <div className="page page-2">
        <OverPack
          className="content"
          playScale={0.4}
          onChange={({ mode }) => {
            setMode(mode);
          }}
        >
          {iconList.map((v, k) => (
            <QueueAnim
              leaveReverse
              className="item"
              delay={mode == 'enter' ? k * 200 : (2 - k) * 200}
              key={k}
              style={{ background: `url(${v.bg}) no-repeat center/cover` }}
            >
              <span key="q1" className="title">
                {v.title}
              </span>
              <span key="q2" className="tips">
                {v.tips}
              </span>
              <div key="q3">
                {v.icon.map((v_, k_) => (
                  <div key={k_}>
                    <img src={v_.url} />
                    <span>{v_.name}</span>
                  </div>
                ))}
              </div>
            </QueueAnim>
          ))}
        </OverPack>
      </div>
      <div className="page page-3">
        <div className="content">
          <span>行业痛点</span>
          <OverPack playScale={0.4} style={{ background: `url(${$page_3_content_bg}) no-repeat center/cover` }}>
            <QueueAnim key="o" leaveReverse type="right">
              <span key="q1">景区</span>
              <span key="q2">在传统景区模式下面临管理效率低下、运营成本高等问题</span>
              <span key="q3">商家</span>
              <span key="q4">资金流、数据流管理效率低，与各部门信息数据割裂，营销能力低</span>
              <span key="q5">消费者</span>
              <span key="q6">较难全面地获取目的地旅游信息，预订门票的渠道分散</span>
              <span key="q7">监管</span>
              <span key="q8">由于缺乏数据支撑，有关部门难以实现动态监督与管理</span>
            </QueueAnim>
          </OverPack>
        </div>
      </div>
      <div className="page page-4" style={{ background: `url(${$page_4_bg}) no-repeat center/cover` }}>
        <div className="content">
          <span>客户需求</span>
          <OverPack playScale={0.4} style={{ background: `url(${$page_4_content_bg}) no-repeat center/cover` }}>
            <QueueAnim key="o" leaveReverse type="bottom">
              <span key="q1">适应时代新需求</span>
              <span key="q2">掌握大众旅游时代下的发展趋势，提升对旅游行业的管理和服务能力</span>
              <span key="q3">景区资源管理</span>
              <span key="q4">打造数字景区平台，实现景区综合管控，实时查看景区各项数据</span>
              <span key="q5">涉旅企业服务</span>
              <span key="q6">整合资源，相互沟通，加强涉旅企业的联系，推进旅游市场秩序规范化</span>
              <span key="q7">游客旅游体验</span>
              <span key="q8">提供预约预定、导游导览平台，提供全流程一站式服务</span>
            </QueueAnim>
          </OverPack>
        </div>
      </div>
      <div className="page page-5">
        <div className="content">
          <span>产品体验</span>
          <div>
            {wrapList.map((v, k) => (
              <OverPack
                playScale={0.3}
                className="wrap"
                key={k}
                onChange={({ mode }) => {
                  setMode5(mode);
                }}
              >
                <QueueAnim
                  leaveReverse
                  type="scale"
                  key="o"
                  delay={mode5 == 'enter' ? k * 100 : (2 - k) * 100}
                  className="wrap__content"
                  onClick={v.regist}
                >
                  <img className="bg" src={v.bg} />
                  <img key="q1" className="icon" src={v.icon} />
                  <span key="q2" className="title">
                    {v.title}
                  </span>
                  {v.content.map((v_) => (
                    <span className="tips" key={v_}>
                      {v_}
                    </span>
                  ))}
                  <div className="regist">注册 / 登录</div>
                </QueueAnim>
                <div className="wrap__bg" />
              </OverPack>
            ))}
          </div>
        </div>
      </div>
      <div className="page page-6" style={{ background: `url(${$page_6_bg}) no-repeat center/cover` }}>
        <div className="content">
          <span>合作景区</span>
          <div>
            <div className="left">
              <div
                className={`img1 ${overIndex == 0 ? 'hover' : ''}`}
                onMouseOver={() => mouseOver(0)}
                onMouseLeave={mouseLeave}
              />
              <span className="border1">{overIndex < 2 && scenicList[overIndex]}</span>
              <div
                className={`img2 ${overIndex == 1 ? 'hover' : ''}`}
                onMouseOver={() => mouseOver(1)}
                onMouseLeave={mouseLeave}
              />
            </div>
            <div className="center">
              <div
                className={`img3 ${overIndex == 2 ? 'hover' : ''}`}
                onMouseOver={() => mouseOver(2)}
                onMouseLeave={mouseLeave}
              />
              <div
                className={`img4 ${overIndex == 3 ? 'hover' : ''}`}
                onMouseOver={() => mouseOver(3)}
                onMouseLeave={mouseLeave}
              />
              <div
                className={`img5 ${overIndex == 4 ? 'hover' : ''}`}
                onMouseOver={() => mouseOver(4)}
                onMouseLeave={mouseLeave}
              />
              <span className="border2">{overIndex > 1 && overIndex < 6 && scenicList[overIndex]}</span>
              <span className="border3" />
              <div
                className={`img6 ${overIndex == 5 ? 'hover' : ''}`}
                onMouseOver={() => mouseOver(5)}
                onMouseLeave={mouseLeave}
              />
            </div>
            <div className="right">
              <div
                className={`img7 ${overIndex == 6 ? 'hover' : ''}`}
                onMouseOver={() => mouseOver(6)}
                onMouseLeave={mouseLeave}
              />
              <div
                className={`img8 ${overIndex == 7 ? 'hover' : ''}`}
                onMouseOver={() => mouseOver(7)}
                onMouseLeave={mouseLeave}
              />
              <span className="border4">{overIndex > 5 && scenicList[overIndex]}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <a href="https://beian.miit.gov.cn/">
          © 2001-{new Date().getFullYear()}「环球数科集团」版权所有 | 粤 ICP 备 09156180 号
        </a>
      </div>
    </div>
  );
};
export default Home;
