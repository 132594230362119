import { useRoutes } from 'react-router-dom';

import Home from '@/views/Home';
import NewForm from '@/views/NewForm';

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '/form',
      element: <NewForm />,
    },
  ]);
}
