import type { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import axios from 'axios';

import { axiosBaseOptions } from '@/network/axios/axios-setup';
import type { Upload } from '@/network/axios/type';
import { message } from 'antd';

class MyAxios {
  private readonly axiosInstance: AxiosInstance;
  constructor(options: AxiosRequestConfig) {
    this.axiosInstance = axios.create(options);
    this.initInterceptors();
  }

  private initInterceptors() {
    // 请求拦截
    // this.axiosInstance.interceptors.request.use(
    //   (config) => {
    //     const token: string | null = localStorage.getItem('token');
    //     if (token) config.headers['authorization'] = `Bearer ${token}`;
    //     return config;
    //   },
    //   (error) => {
    //     console.log(`axios请求拦截部分报错，错误信息error`, error);
    //     return Promise.reject(error);
    //   },
    // );

    // 响应拦截
    this.axiosInstance.interceptors.response.use(
      (response) => {
        const { data } = response;
        if (data.code !== 20000) {
          message.info(data.msg)
          return Promise.reject(data.data);
        }
        return data
      },
      (error: AxiosError) => {
        console.log('axios响应拦截部分发生错误，错误信息为', error);
        if(error?.response){
          switch (error.response.status){
            case 400:
              message.error('请求错误');
                break;
            case 401:
              message.error('未授权访问');
                break;
            case 404:
              message.error('资源未找到');
                break;
            default:
              message.error('其他错误信息');
          }
        }
        return Promise.reject(error);
      },
    );
  }

  get<T>(url: string, data?: object): Promise<T> {
    return this.axiosInstance.get(url, { params: data });
  }

  post<T>(url: string, data?: object, params?: object): Promise<T> {
    return this.axiosInstance.post(url, data, { params });
  }

  put<T>(url: string, data?: object, params?: object): Promise<T> {
    return this.axiosInstance.put(url, data, { params });
  }

  delete<T>(url: string, data?: object): Promise<T> {
    return this.axiosInstance.delete(url, { params: data });
  }

  upload<T>(data: Upload): Promise<T> {
    const { url, formData, controller, onUploadProgress } = data;
    return this.axiosInstance.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress,
      signal: controller ? controller.signal : undefined, // 用于文件上传可以取消
    });
  }
}

export const request = new MyAxios(axiosBaseOptions);
