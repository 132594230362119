import './index.less';

import { CheckCircleOutlined, HarmonyOSOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons';
import $form_close from '@assets/form_close.png';
import $header_logo from '@assets/header_logo.png';
import Province from '@components/Province';
import { Button, Flex, Form, Input, Modal, Spin, DatePicker, Select, message, ConfigProvider, Radio, Row, Col, Progress, Statistic } from 'antd';
import { useEffect, useState } from 'react';
import { cloneDeep, random } from 'lodash';
import { useInterval, useRequest, useTimeout } from 'ahooks';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

import {
  apiAppScenicExperienceInfo,
  apiBankList,
  apiCheckCoCode,
  apiCheckUniqueIdentity,
  apiPersonalTryout,
  apiRenewScenic,
  apiScenicUniqueIdentity,
  apiUserOtp,
  appScenicPermission,
} from '@/network/api/api';

import { useNavigate } from 'react-router-dom';

import React from 'react';
import type { FormInstance, StepsProps } from 'antd';
import { Popover, Steps } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import ImageUpload from '@/components/ImageUpload';
import moment from 'moment';
import 'moment/locale/zh-cn';
import zhCN from 'antd/locale/zh_CN';
import { mobileValidator } from '@/common/utils/validate';
import { randomUUID } from 'crypto';

const DEFAULT_LOGO = import.meta.env.VITE_DEFAULT_LOGO;
const SCENIC_HOST = import.meta.env.VITE_SCENIC_HOST;

const { RangePicker } = DatePicker;

const GradeEnum = [
  { label: '未评', value: 0 },
  { label: '国家 A 级景区', value: 1 },
  { label: '国家 2A 级景区', value: 2 },
  { label: '国家 3A 级景区', value: 3 },
  { label: '国家 4A 级景区', value: 4 },
  { label: '国家 5A 级景区', value: 5 },
];

const DocumentTypeEnum = [
  { label: '身份证', value: 'ID_CARD' },
  // { label: '护照', value: 'PASSPORT' },
  // { label: '港澳居民来往内地通行证', value: 'HK_MACAU_PERMIT' },
  // { label: '台湾居民来往大陆通行证', value: 'TAIWAN_PERMIT' },
];

//有效期类型，可用值:FIXED,LONG_TERM
export const ExpirationDateTypeEnum = [
  { label: '长期', value: 'LONG_TERM' },
  { label: '固定', value: 'FIXED' },
];

const Page = () => {
  const customDot: StepsProps['progressDot'] = (dot, { status, index }) => {
    // <Popover
    //   content={
    //     <span>
    //       step {index} status: {status}
    //     </span>
    //   }
    // >
    return status == 'wait' ? <HarmonyOSOutlined style={{ fontSize: '25px', color: '#08c' }} /> : <CheckCircleOutlined />
  }

  const steps = [
    {
      title: '景区信息',
    },
    {
      title: '企业信息',
    },
    {
      title: '法人信息',
    },
    {
      title: '收款信息',
      pass: 1,
    },
    {
      title: '联系信息',
    },
  ]

  const location = useLocation();

  const search = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const isCreateScenic = (search.type ?? 0) == 0 // 是否是创建景区的
  if (!isCreateScenic) {//如果只建企业，就去除景区的字段
    steps.splice(0, 1)
  }

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [canGoLogin, setCanGoLogin] = useState(false);//简称是否存在，存在则直接可登录
  const [countdownTime, setCountdownTime] = useState(0);//验证码的倒计时
  const [canSendOPT, setCanSendOPT] = useState(0);//可否发验证码，0 可发 1 不可发 2 可重发

  const [currentStep, setCurrentStep] = useState(0);//当前步骤
  const [progress, setProgress] = useState(0);//当前注册进度，step=1
  const [progressText, setProgressText] = useState<{ text: string, style: any }[]>(
    new Array(2).fill(null).map(_ => {
      //只能这么写，如果把对象放到 fill 之中，[0]==[1] 为 true 即都为一个对象，这样逻辑就会有问题
      return {
        text: "", style: { opacity: 0, transition: "0.5s", position: "absolute", "transitionDelay": "0s" }
      }
    }));//存储当前进度的提示词与样式
  const [formRef] = Form.useForm()//单页只能有一个实例？曾想用过实例数组，结果不生效
  const formData = useRef<any[]>([])//用一个数组来把每一步的数据都保存起来

  // 银行下拉列表
  const bankDownListReq = useRequest(apiBankList, {});

  // 发送验证码
  const send = () => {
    const phone = formRef.getFieldValue("contactPhone")
    if (!/^1[3-9]\d{9}$/.test(phone)) {
      message.warning("请输入正确手机号！")
      return;
    }
    if (canSendOPT != 1) {
      setCanSendOPT(1)
      setCountdownTime(Date.now() + 60 * 1000)
      apiUserOtp({ credential: phone, type: 2 })
    }
  };

  /**
   * 上一步按钮
   */
  const prevStep = () => {
    if (currentStep == 0) {//第一页视为关闭
      navigate(-1);
    } else {
      const step = currentStep - 1
      formRef.setFieldsValue(formData.current[step])//把上一步的数据还原
      setCurrentStep(step)
    }
  }

  /**
  * 下一步按钮
  */
  const nextStep = () => {
    formRef.submit()
  }

  /**
   * 已经注册过可直接登录
   */
  const goLogin = () => {
    let redirection = import.meta.env.VITE_URL_EXCHANGE //成功后的跳转地址
    //如果只建景区才有景区的信息
    if (isCreateScenic) {
      const uniqueIdentity = formRef.getFieldValue("uniqueIdentity")
      redirection = SCENIC_HOST + '/#/' + uniqueIdentity + '/'
    }

    window.location.href = redirection
  }

  const onFormFinish = () => {
    console.log(formRef.getFieldsValue())
    formData.current[currentStep] = cloneDeep(formRef.getFieldsValue())//把这一步的数据保存下来
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1)
    } else {//最后一步
      // formData.current.map((d) => {
      //   console.log(d)
      // })
      submitSignUp()
    }
  }

  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  /**
   * 提交注册
   */
  const submitSignUp = async () => {
    let scenicInfo = formData.current[0];
    let companyInfo = formData.current[1];
    let leaderInfo = formData.current[2];
    let bankInfo = formData.current[3];
    let contactInfo = formData.current[4];

    if (!isCreateScenic) {
      //如果只建企业，就去除景区的字段
      companyInfo = formData.current[0];
      leaderInfo = formData.current[1];
      bankInfo = formData.current[2];
      contactInfo = formData.current[3];
    }

    //如果是固定的再设置
    let businessLicenseBeginDate = undefined;
    let businessLicenseEndDate = undefined;
    if (companyInfo.licenseExpirationDateType == ExpirationDateTypeEnum[1].value) {
      businessLicenseBeginDate = companyInfo.businessLicenseDate[0].format('YYYY-MM-DD');
      businessLicenseEndDate = companyInfo.businessLicenseDate[1].format('YYYY-MM-DD');
    }

    //如果是固定的再设置
    let documentBeginDate = undefined;
    let documentEndDate = undefined;
    if (leaderInfo.documentExpirationDateType == ExpirationDateTypeEnum[1].value) {
      documentBeginDate = leaderInfo.documentDate[0].format('YYYY-MM-DD');
      documentEndDate = leaderInfo.documentDate[1].format('YYYY-MM-DD');
    }

    const postForm: any = {
      appScenicPermissionDTO: {
        co: {
          coCode: companyInfo.coCode,
          coName: companyInfo.coName,
          isOTA: 0,
          contactName: contactInfo.contactName,
          contactPhone: contactInfo.contactPhone,
          licenseExpirationDateType: companyInfo.licenseExpirationDateType,
          businessLicenseBeginDate,
          businessLicenseEndDate,
          businessLicenseImageUrl: companyInfo.businessLicenseImageUrl,
          legalRepresentativeName: leaderInfo.legalRepresentativeName,
          documentType: leaderInfo.documentType,
          documentNumber: leaderInfo.documentNumber,
          documentExpirationDateType: leaderInfo.documentExpirationDateType,
          documentBeginDate,
          documentEndDate,
          documentImageUrl: leaderInfo.documentImageUrl,
        },
        isSale: true,
        relationCoAndType: [
          {
            type: isCreateScenic ? 0 : 1,
          },
        ],
        system: 1,
      },
      experienceInfoDTO: {
        autoRegister: true,
        credential: contactInfo.contactPhone,
        contractsName: contactInfo.contactName,
        loginTypeCode: 4,
        secret: contactInfo.secret, //验证码
      },
    };

    //企业地址信息添加
    let coProvinceInfo: any = { coAddressInfo: companyInfo.coAddressInfo };
    if (companyInfo.coProvince != undefined) {
      coProvinceInfo = {
        ...coProvinceInfo,
        coProvinceName: companyInfo.coProvince.provinceName,
        coProvinceCode: companyInfo.coProvince.provinceCode,
        coCityName: companyInfo.coProvince.cityName,
        coCityCode: companyInfo.coProvince.cityCode,
        coAreaName: companyInfo.coProvince.areaName,
        coAreaCode: companyInfo.coProvince.areaCode,
      };
    }
    if (JSON.stringify(coProvinceInfo) != '{}') {
      //之中有属性有值，则加入
      postForm.appScenicPermissionDTO.coAddress = coProvinceInfo;
    }

    let redirection = import.meta.env.VITE_URL_EXCHANGE; //成功后的跳转地址
    //如果只建景区才有景区的信息
    if (isCreateScenic) {
      redirection = SCENIC_HOST + '/#/' + scenicInfo.uniqueIdentity + '/';

      let provinceInfo = {};
      if (scenicInfo.scenicProvince != undefined) {
        provinceInfo = {
          provinceName: scenicInfo.scenicProvince.provinceName,
          provinceCode: scenicInfo.scenicProvince.provinceCode,
          cityName: scenicInfo.scenicProvince.cityName,
          cityCode: scenicInfo.scenicProvince.cityCode,
          areaName: scenicInfo.scenicProvince.areaName,
          areaCode: scenicInfo.scenicProvince.areaCode,
        };
      }

      postForm.scenicExperienceDTO = {
        address: scenicInfo.address,
        appInfo: {
          appBrief: '基于联盟链的全场',
          applicationName: scenicInfo.scenicName + '景区管理系统',
          imgUrl: DEFAULT_LOGO,
          loginType: 2,
          logoutType: 1,
          type: 1,
          url: redirection,
          enterpriseId: 1,
        },
        ...provinceInfo,
        grade: scenicInfo.grade,
        scenicName: scenicInfo.scenicName,
        uniqueIdentity: scenicInfo.uniqueIdentity,
      };
    }

    //有写银行账号再填银行信息
    if (bankInfo.account != undefined) {
      postForm.appScenicPermissionDTO.coBankAccount = {
        bankId: bankInfo.bankId,
        bankName: bankInfo.bankName,
        accountName: companyInfo.coName,
        account: bankInfo.account,
      };
    }

    // console.log(postForm)
    setLoading(true);
    // 先写假的，到时候再跳
    // setInterval(interval + 1)//激活记时器
    const sseCode = sseProgress();
    // localStorage.setItem('postForm', JSON.stringify(postForm));
    // 延时 2s 再提交
    await delay(2000);
    try {
      let response = await fetch(`${import.meta.env.VITE_API_BASE}/co/scenic/personal/tryout?sseCode=${sseCode}`, {
        method: 'post',
        body: JSON.stringify({
          ...postForm,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log(data);

      setTimeout(() => {
        setLoading(false);
        if (data.code == 20000) {
          message.success('注册成功');
          window.location.href = redirection;
        } else {
          setProgressState({
            title: '开始注册',
          });
          message.error(data.msg);
        }
      }, 500);
      // const reader = response.body?.getReader()!!;
      // const textDecoder = new TextDecoder();
      // let result = true;
      // let output = '';
      // while (result) {
      //   const { done, value } = await reader.read();

      //   if (done) {
      //     console.log('Stream ended');
      //     result = false;
      //     break;
      //   }
      //   const chunkText = textDecoder.decode(value);
      //   output += chunkText;
      //   console.log('Received chunk:', chunkText);
      // }
      //成功后跳转
      // window.location.href = redirection
    } catch (e) {
      console.log(e);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  /**
   * 获取当前步骤的表单
   * @param step
   * @returns
   */
  const forms = [
    <Form layout="vertical" form={formRef} size="large" onFinish={onFormFinish}>
      <FormItem label="景区名称" name="scenicName" rules={[{ required: true, message: '请输入' }, { max: 50 }]}>
        <Input placeholder="请输入" />
      </FormItem>
      <FormItem label="景区等级" name="grade">
        <Select options={GradeEnum} placeholder="请选择" />
      </FormItem>
      <FormItem label="景区所在地（省市区）" name="scenicProvince">
        <div key="province">
          <Province
            value={() => {
              const data = formData.current[currentStep];
              if (data == undefined) {
                return [];
              }
              const province = data.scenicProvince;
              if (province == undefined) {
                return [];
              }
              return [province.provinceName, province.cityName, province.areaName];
            }}
            width="100%"
            onChange={(e: any) => {
              const provinceData = {
                provinceName: e?.[0]?.addressName,
                provinceCode: e?.[0]?.addressId,
                cityName: e?.[1]?.addressName,
                cityCode: e?.[1]?.addressId,
                areaName: e?.[2]?.addressName,
                areaCode: e?.[2]?.addressId,
              };
              formRef.setFieldValue('scenicProvince', provinceData);
            }}
          />
        </div>
      </FormItem>
      <FormItem label="详细地址" name="address" rules={[{ max: 100 }]}>
        <Input placeholder="请输入" />
      </FormItem>
      <FormItem
        label="英文/拼音简称"
        name="uniqueIdentity"
        validateTrigger="onBlur"
        rules={[
          { required: true },
          { max: 20 },
          {
            validator: async (_, value) => {
              if (value != undefined && value.length <= 20) {
                //没输入就不用查
                const { data }: any = await apiCheckUniqueIdentity(value);
                if (data) {
                  //true表示已经占用
                  setCanGoLogin(true); //直接可以登录
                  return Promise.reject('此景区已注册，请直接登录！');
                }
              }
              setCanGoLogin(false);
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input placeholder="将作为访问地址唯一标识，不可更改！" />
      </FormItem>
    </Form>,
    <Form layout="vertical" form={formRef} size="large" onFinish={onFormFinish}>
      <FormItem label="企业名称" name="coName" rules={[{ required: true }, { max: 50 }]}>
        <Input placeholder="请输入" />
      </FormItem>
      <FormItem
        label="统一社会信用代码"
        name="coCode"
        validateTrigger="onBlur"
        rules={[
          { required: true, len: 18 },
          {
            validator: async (_, value) => {
              if (value != undefined && value.length == 18) {
                //没输入就不用查
                const { data }: any = await apiCheckCoCode(value);
                if (!data) {
                  setCanGoLogin(true); //直接可以登录
                  return Promise.reject('统一社会信用代码已存在！');
                }
              }
              setCanGoLogin(false); //直接可以登录
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input placeholder="请输入" />
      </FormItem>
      <FormItem label="企业所在地（省市区）" name="coProvince">
        <div key="province">
          <Province
            value={() => {
              const data = formData.current[currentStep];
              if (data == undefined) {
                return [];
              }
              const province = data.coProvince;
              if (province == undefined) {
                return [];
              }
              return [province.provinceName, province.cityName, province.areaName];
            }}
            width="100%"
            onChange={(e: any) => {
              const provinceData = {
                provinceName: e?.[0]?.addressName,
                provinceCode: e?.[0]?.addressId,
                cityName: e?.[1]?.addressName,
                cityCode: e?.[1]?.addressId,
                areaName: e?.[2]?.addressName,
                areaCode: e?.[2]?.addressId,
              };
              formRef.setFieldValue('coProvince', provinceData);
            }}
          />
        </div>
      </FormItem>
      <FormItem label="详细地址" name="coAddressInfo" rules={[{ max: 100 }]}>
        <Input placeholder="请输入" />
      </FormItem>
      <div>
        <FormItem dependencies={['licenseExpirationDateType']} className="overlap-date-picker">
          {() => {
            const require =
              (formRef.getFieldValue('licenseExpirationDateType') ?? ExpirationDateTypeEnum[1].value) ==
              ExpirationDateTypeEnum[1].value;

            return (
              <FormItem
                label="营业执照有效期"
                name="businessLicenseDate"
                rules={[{ required: require, message: '请输入' }]}
              >
                <RangePicker disabled={!require} style={{ width: '100%' }} />
              </FormItem>
            );
          }}
        </FormItem>
        <FormItem
          name="licenseExpirationDateType"
          rules={[{ required: true, message: '请输入' }]}
          className="overlap-date-type"
          initialValue={ExpirationDateTypeEnum[1].value}
        >
          <Radio.Group options={ExpirationDateTypeEnum} />
        </FormItem>
      </div>
      <FormItem label="营业执照上传" name="businessLicenseImageUrl" rules={[{ required: true, message: '请上传' }]}>
        <ImageUpload maxCount={1} defaultValue={formRef.getFieldValue('businessLicenseImageUrl')} size={5} />
      </FormItem>
    </Form>,
    <Form layout="vertical" form={formRef} size="large" onFinish={onFormFinish}>
      <FormItem label="法人名称" name="legalRepresentativeName" rules={[{ required: true }, { max: 10 }]}>
        <Input placeholder="请输入" />
      </FormItem>
      <FormItem label="证件类型" name="documentType" rules={[{ required: true }]}>
        <Select options={DocumentTypeEnum} placeholder="请选择" />
      </FormItem>
      <FormItem label="证件号码" name="documentNumber" rules={[{ required: true }]}>
        <Input placeholder="请输入" />
      </FormItem>
      <div>
        <FormItem dependencies={['documentExpirationDateType']} className="overlap-date-picker">
          {() => {
            const require =
              (formRef.getFieldValue('documentExpirationDateType') ?? ExpirationDateTypeEnum[1].value) ==
              ExpirationDateTypeEnum[1].value;

            return (
              <FormItem label="身份证有效期" name="documentDate" rules={[{ required: require, message: '请输入' }]}>
                <RangePicker disabled={!require} style={{ width: '100%' }} />
              </FormItem>
            );
          }}
        </FormItem>
        <FormItem
          name="documentExpirationDateType"
          rules={[{ required: true, message: '请输入' }]}
          className="overlap-date-type"
          initialValue={ExpirationDateTypeEnum[1].value}
        >
          <Radio.Group options={ExpirationDateTypeEnum} />
        </FormItem>
      </div>
      <FormItem
        label="证件正反面上传"
        name="documentImageUrl"
        rules={[
          { required: true, message: '请上传证件正反面' },
          {
            validator: (_, value) => {
              if (value.split(',').length == 2) {
                return Promise.resolve();
              }
              return Promise.reject('必须上传两张');
            },
          },
        ]}
      >
        <ImageUpload maxCount={2} defaultValue={formRef.getFieldValue('documentImageUrl')} size={5} />
      </FormItem>
    </Form>,
    <Form layout="vertical" form={formRef} size="large" onFinish={onFormFinish}>
      <FormItem label="开户名称" name="accountName">
        <Input defaultValue={formData.current[isCreateScenic ? 1 : 0]?.coName ?? ''} disabled />
      </FormItem>
      <FormItem name="bankName" hidden={true} />
      <FormItem label="开户银行" name="bankId">
        <Select
          loading={bankDownListReq.loading}
          onChange={(value, option: any) => {
            console.log(value, option);
            formRef.setFieldValue('bankName', option.label);
          }}
          options={bankDownListReq.data?.data?.map(({ bankId, bankName }) => {
            return {
              label: bankName,
              value: bankId,
            };
          })}
          placeholder="请选择"
        />
      </FormItem>
      <FormItem label="银行账户" name="account">
        <Input placeholder="请输入" />
      </FormItem>
    </Form>,
    <Form layout="vertical" form={formRef} size="large" onFinish={onFormFinish}>
      <FormItem label="联系人" name="contactName" rules={[{ required: true }, { max: 10 }]}>
        <Input placeholder="请输入" />
      </FormItem>
      <FormItem label="联系手机" name="contactPhone" rules={[{ required: true }, mobileValidator()]}>
        <Input placeholder="请输入" type="tel" />
      </FormItem>
      <FormItem label="验证码" name="secret" rules={[{ required: true }, { len: 4 }]}>
        <Input
          placeholder="请输入"
          type="number"
          addonAfter={
            <div onClick={send}>
              <div style={{ display: canSendOPT == 1 ? 'none' : 'block' }}>
                {canSendOPT == 0 ? '发送验证码' : '重新发送'}
              </div>
              {canSendOPT == 1 ? (
                <Statistic.Countdown
                  value={countdownTime}
                  format="s"
                  onFinish={() => {
                    setCanSendOPT(2);
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          }
        />
      </FormItem>
    </Form>,
  ];
  if (!isCreateScenic) {
    //如果只建企业，就去除景区的字段
    forms.splice(0, 1);
  }

  const sseProgress = () => {
    const sseCode = Date.now();
    const eventSource = new EventSource(`${import.meta.env.VITE_API_BASE}/sse/get/${sseCode}`);
    eventSource.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        console.log(data);
        simulateProgress(data);
        // scenicDetail.content += data.choices[0].delta.content
      } catch (error) {
        console.error(error);
      }
    };

    eventSource.onopen = () => {
      console.log('Connection to server opened.');
    };

    eventSource.onerror = (event) => {
      if (event?.readyState === EventSource.CLOSED) {
        console.log('Connection to server closed.');
      } else {
        console.error('Error occurred:', event);
      }
    };

    return sseCode;
  };

  /**
   * 设置下一条要显示的提示
   */
  const progressTextChange = (progress: any) => {
    let newData = cloneDeep(progressText);
    const showFirst = newData[0].style.opacity == 0; //是否显示第一个
    const showIndex = showFirst ? 0 : 1; //要显示的下标
    const hideIndex = showFirst ? 1 : 0; //要隐藏的下标

    newData[showIndex].text = progress.title;
    newData[showIndex].style.opacity = 1;
    newData[showIndex].style['transitionDelay'] = '0.5s'; //显示要带延时
    newData[hideIndex].style.opacity = 0;
    newData[hideIndex].style['transitionDelay'] = '0s'; //0 帧起手隐藏

    setProgressText(newData);
  };

  const progressList = [
    {
      title: '创建超级管理员账号',
      key: 'CREATE_USER',
      progree: 8,
    },
    {
      title: '创建景区',
      key: 'CREATE_SCENIC',
      progree: 16,
    },
    {
      title: '创建企业',
      key: 'CREATE_COMPANY',
      progree: 24,
    },
    {
      title: '开通慧景云权限',
      key: 'SCENIC_PERMISSION',
      progree: 62,
    },
    {
      title: '开通易旅通权限',
      key: 'COMPANY_PERMISSION',
      progree: 90,
    },
    {
      title: '完成，正在跳转',
      key: 'COMPLETE',
      progree: 100,
    },
  ];
  const [progressState, setProgressState] = useState<any>({
    title: '开始注册',
  });

  /**
   * 模拟进度条
   */
  const simulateProgress = (key: string) => {
    const progress = progressList.find((item) => item.key === key) || { progree: 0 };
    setProgressState(progress);
    progressTextChange(progress);

    if (key === 'COMPLETE') {
      let scenicInfo = formData.current[0];
      let redirection = import.meta.env.VITE_URL_EXCHANGE; //成功后的跳转地址
      if (isCreateScenic) {
        redirection = SCENIC_HOST + '/#/' + scenicInfo?.uniqueIdentity ?? 'none' + '/';
      }
      setTimeout(() => {
        window.location.href = redirection;
      }, 500);
    }
  };

  const sdfsdsdsf = async () => {
    setLoading(true);
    //   setInterval(interval + 1)
    const sseCode = sseProgress();
    const postForm = JSON.parse(localStorage.getItem('postForm'));
    await delay(2000);
    let response = await fetch(`${import.meta.env.VITE_API_BASE}/co/scenic/personal/tryout?sseCode=${sseCode}`, {
      method: 'post',
      body: JSON.stringify({
        ...postForm,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    console.log(data);
    setTimeout(() => {
      setLoading(false);
      if (data.code == 20000) {
        message.success('注册成功');
        // window.location.href = redirection;
      } else {
        message.error(data.msg);
      }
    }, 500);

    // const reader = response.body?.getReader()!!;
    // const textDecoder = new TextDecoder();
    // let result = true;
    // let output = '';
    // while (result) {
    //   const { done, value } = await reader.read();

    //   if (done) {
    //     console.log('Stream ended');
    //     result = false;
    //     break;
    //   }
    //   const chunkText = textDecoder.decode(value);
    //   output += chunkText;
    //   console.log('Received chunk:', chunkText);
    // }
  };

  return (
    <div className="form">
      {/* <div className="header">
        <img className="logo" src={$header_logo} />
      </div> */}
      <div className="steps">
        <Steps current={currentStep} progressDot items={steps} />
      </div>
      <div className="wrap">
        {steps[currentStep].pass == 1 ? (
          <div className="pass">
            <a style={{ color: '#216EF3' }} onClick={nextStep}>
              暂不开通，跳过
            </a>
          </div>
        ) : (
          <></>
        )}
        <ConfigProvider locale={zhCN}>
          {loading ? (
            <div style={{ padding: '200px 100px' }}>
              <Progress
                percent={progressState.progree}
                showInfo={false}
                strokeColor={{
                  '0%': '#1677ff',
                  '100%': '#1677ff',
                }}
              />
              <Flex justify="center">
                <span>{progressState.title}</span>
                {/* {progressText[0] != undefined ? (
                  <span style={progressText[0].style}>{progressText[0].text}</span>
                ) : (
                  <></>
                )}
                {progressText[1] != undefined ? (
                  <span style={progressText[1].style}>{progressText[1].text}</span>
                ) : (
                  <></>
                )} */}
              </Flex>
            </div>
          ) : (
            forms[currentStep]
          )}
        </ConfigProvider>
        {!loading && canGoLogin ? (
          <Flex justify="center" gap="large">
            <Button type="primary" onClick={goLogin}>
              去登录
            </Button>
          </Flex>
        ) : (
          <Flex justify="center" gap="large">
            {!loading && currentStep >= 0 && (
              <Button style={{ margin: '0 8px' }} onClick={prevStep}>
                上一步
              </Button>
            )}
            {!loading && currentStep < steps.length - 1 && (
              // <Button type="primary" onClick={sdfsdsdsf}>
              //   sdfsdsdsf
              // </Button>
              <Button type="primary" onClick={nextStep}>
                下一步
              </Button>
            )}
            {!loading && currentStep == steps.length - 1 && (
              <Button type="primary" onClick={nextStep}>
                提交
              </Button>
            )}
          </Flex>
        )}
      </div>
      <div className="footer">
        <a href="https://beian.miit.gov.cn/">
          © 2001-{new Date().getFullYear()}「环球数科集团」版权所有 | 粤 ICP 备 09156180 号
        </a>
      </div>
    </div>
  );
};
export default Page;
