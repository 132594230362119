import { request } from '@/network/axios';
const host = import.meta.env.VITE_API_BASE;
const casHost = import.meta.env.VITE_API_CAS;

// 查询景区是否存在(根据唯一标识)
export function apiCheckUniqueIdentity(id: string) {
  return request.get(`${host}/scenic/noAuth/check/uniqueIdentity/${id}`);
}

// 景区信息查询(根据唯一标识)
export function apiScenicUniqueIdentity(id: string) {
  return request.get(`${host}/scenic/noAuth/uniqueIdentity/info/${id}`);
}

// 试用期续期
export function apiRenewScenic(id: string) {
  return request.put(`${host}/scenic/noAuth/renewScenic/${id}`);
}

// 发送短信
export function apiUserOtp(params: Record<string, unknown>) {
  return request.get(`${host}/user/otp`, params);
}

// 景区登录注册
export function apiAppScenicExperienceInfo(data: Record<string, unknown>) {
  return request.post(`${host}/appScenic/experienceInfo`, data);
}

// 景区完善企业信息并授权
export function appScenicPermission(data: Record<string, unknown>) {
  return request.post(`${host}/appScenic/appScenicPermission`, data);
}

// 登录
export function apiUserNoAuthLogin(data: Record<string, unknown>) {
  return request.post(`${casHost}/user/login`, {
    ...data,
    skipErrorHandler: true,
  });
}

// 获取省市区
export function getProvince(params: any) {
  return request.get(`${host}/address/info`, params);
}

// 获取银行列表
export function apiBankList(params: any) {
  return request.get(`${host}/bank/list`, params);
}

// 落地页，景区企业注册
export function apiPersonalTryout(data: Record<string, unknown>) {
  return request.post(`${host}/co/scenic/personal/tryout`, data);
}

// 落地页，查询信用号是否占用
export function apiCheckCoCode(id: string) {
  return request.get(`${host}/co/coCode/auth?coCode=${id}`);
}