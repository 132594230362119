/*
 * @Author: zhangfengfei
 * @Date: 2022-07-25 17:50:21
 * @LastEditTime: 2022-07-29 15:47:48
 * @LastEditors: zhangfengfei
 */
import { PlusOutlined } from '@ant-design/icons';
import { message, Modal, Upload } from 'antd';
import type { ImgCropProps } from 'antd-img-crop';
import ImgCrop from 'antd-img-crop';
import 'antd/es/slider/style';
import type { RcFile, UploadFile } from 'antd/lib/upload/interface';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

const imgHost = import.meta.env.VITE_IMAGE_HOST;
/**
 * 获取随机 id，可用于唯一标识
 * @return 数字与字母混合的字符串
 **/
const getUniqueId = () => {
  const s = [];
  const hexDigits = '0123456789abcdef';
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  // bits 12-15 of the time_hi_and_version field to 0010
  s[14] = '4';
  // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
  s[8] = s[13] = s[18] = s[23] = '-';

  return s.join('');
};


interface ResponseFileDataItem {
  size: number;
  path: string;
  name: string;
  type: string;
  mtime: string;
}
// 上传接口返回数据
type ResponseFileData = ResponseFileDataItem[];

type ImageType = 'jpg' | 'png' | 'jpeg';

interface ImageUploadProps {
  className?: string;
  defaultValue?: string;
  value?: string;
  /** 上传列表变化的回调 */
  onChange?: (value: string) => void;
  /** 单文件大小  M */
  size?: number;
  /** 图片最大上传数量 */
  maxCount?: number;
  /** 允许上传的图片格式，默认 ['jpeg', 'jpg', 'png'] */
  imgTypes?: ImageType[];
  /** 是否支持批量上传 */
  multiple?: boolean;
  /** 上传地址 */
  action?: string;
  readonly?: boolean;
  /** 图片裁剪配置 https://github.com/nanxiaobei/antd-img-crop */
  ImgCropConfig?: Omit<ImgCropProps, 'children'>;
}
/**
 * @params 如被 FormItem 嵌套，value 和 onChange 无需再定义
 * @description  图片上传组件 有编辑和只读两个模式
 *
 */
const ImageUpload: FC<ImageUploadProps> = ({
  className,
  value,
  defaultValue,
  onChange: onValuesChange,
  maxCount = 1,
  imgTypes = ['jpeg', 'jpg', 'png'],
  multiple = false,
  readonly = false,
  size = 100,
  action = imgHost,
  ImgCropConfig,
}) => {
  const fileTypes = [...new Set(imgTypes)];
  const [imgList, setImgList] = useState<UploadFile<ResponseFileData>[]>([]);

  const [current, setCurrent] = useState<UploadFile<ResponseFileData>>();
  const [previewVisible, setPreviewVisible] = useState(false);

  // 上传前图片格式和大小校验
  const beforeUpload = (file: RcFile, FileList: RcFile[]) => {
    const typeFlag = fileTypes.map((type) => `image/${type}`).includes(file.type);
    if (!typeFlag) {
      // jpg 和 jpeg 是同一个格式
      message.error(`只允许上传${fileTypes.join(',')}格式的图片!`);
    }
    const sizeFlag = file.size / 1024 / 1024 < size;
    if (!sizeFlag) {
      message.error(`图片必须小于${size}M!`);
    }
    return (typeFlag && sizeFlag) || Upload.LIST_IGNORE;
  };

  // 上传change事件
  const onChange = ({
    file,
    fileList,
  }: {
    file: UploadFile<ResponseFileData>;
    fileList: UploadFile<ResponseFileData>[];
  }) => {
    const { status } = file;
    // 上传成功处理数据
    if (status === 'done') {
      // console.log('status:', file.status);
      // console.log('file:', file);
      // console.log('filelist:', fileList);
      setImgList(
        fileList.map((item) => {
          if (item.status === 'done') {
            return {
              ...item,
              // 预览地址
              thumbUrl: item.response
                ? imgHost + item.response[0]?.path
                : item.thumbUrl,
              // preview 字段暂时用来存后端图片返回地址
              preview: item.response ? item.response[0]?.path : item.preview,
            };
          }
          return item;
        }),
      );
      return;
    }
    if (status === 'error') {
      setImgList(fileList.filter((item) => item.status !== 'error'));
      message.error('图片上传失败！');
      return;
    }
    console.log('status:', file.status);
    console.log('file:', file);
    console.log('filelist:', fileList);

    setImgList(fileList);
  };

  // 图片回显
  useEffect(() => {
    console.log('defaultValue:', defaultValue);

    if (defaultValue) {
      const defaultFileList: UploadFile<ResponseFileData>[] = defaultValue
        .split(',')
        .map((item) => ({
          uid: getUniqueId(),
          name: item,
          percent: 100,
          status: 'done',
          thumbUrl: imgHost + item,
          preview: item,
        }));
      setImgList(defaultFileList);
      console.log('defaultValue:', defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (onValuesChange) {
      // 需过滤上传失败的图片
      const changedValue = imgList
        .filter((item) => item.preview)
        .map((item) => item.preview)
        .join(',');
      onValuesChange(changedValue);
      console.log('_________', changedValue);
    }
  }, [imgList]);

  const accept = fileTypes.map((type) => `.${type}`).join(',');

  const uploadComponent = (
    <Upload
      className={className}
      fileList={imgList}
      listType="picture-card"
      accept={accept}
      maxCount={maxCount}
      beforeUpload={beforeUpload}
      onChange={onChange}
      onPreview={(file) => {
        setCurrent(file);
        setPreviewVisible(true);
      }}
      showUploadList={{
        showRemoveIcon: !readonly,
      }}
      multiple={multiple}
      action={action}
    >
      {imgList.length < maxCount && !readonly ? <PlusOutlined /> : null}
    </Upload>
  );

  return (
    <>
      {ImgCropConfig ? <ImgCrop {...ImgCropConfig}>{uploadComponent}</ImgCrop> : uploadComponent}
      <Modal
        visible={previewVisible}
        title={'预览'}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img alt="图片预览" style={{ width: '100%' }} src={current?.thumbUrl} />
      </Modal>
    </>
  );
};

export default ImageUpload;
