/*
 * @Author: zhangfengfei
 * @Date: 2022-08-30 10:54:24
 * @LastEditTime: 2022-08-30 11:18:13
 * @LastEditors: zhangfengfei
 */
/*
 * @Author: zhangfengfei
 * @Date: 2022-08-10 17:22:49
 * @LastEditTime: 2022-08-10 17:47:10
 * @LastEditors: zhangfengfei
 */
import type { Rule } from 'antd/lib/form';
import { isString } from 'lodash';
// import  from 'antd';

// 正则表达式

/**
 * 中英文字符长度，中文算两个长度
 * @param {*} str
 */
export function strlen(str: string) {
  return str.length;
}

/**
 * 验证是否包含数字和字母
 * @param {string} value
 */
export function validateLetterAndNumber(value: string) {
  return new RegExp('^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]*$').test(value);
}

/**
 * 验证是否为正确ip
 * @param {string} value
 */
export function validateIp(value: string) {
  const reg =
    /^([1-9]?\d|1\d\d|2[0-4]\d|25[0-5])\.([1-9]?\d|1\d\d|2[0-4]\d|25[0-5])\.([1-9]?\d|1\d\d|2[0-4]\d|25[0-5])\.([1-9]?\d|1\d\d|2[0-4]\d|25[0-5])$/;
  return reg.test(value);
}

/**
 * 验证是否为正确手机号
 * @param {string} value
 */
export function validateMobile(value: any) {
  const reg = /^1[3-9]\d{9}$/;
  return reg.test(value);
}

/* ------------------------------------------------------------------------------------------------------------------------- */

/**
 * 验证器 只允许输入英文或者数字
 * @param {string} errorMessage
 */
export function letterOrNumberValidator(errorMessage: string = '只允许输入英文或者数字'): Rule {
  const result: Rule = {
    validator: (rule, value) => {
      if (value && !/^[A-Za-z0-9]+$/.test(value)) {
        return Promise.reject(errorMessage);
      }
      return Promise.resolve();
    },
  };

  return result;
}

/**
 * 验证器 字符串是否包含数字和字母
 * @param {string} errorMessage
 */
export function letterAndNumberValidator(errorMessage: string = '必须是字母和数字结合'): Rule {
  const result: Rule = {
    validator: (rule, value) => {
      const pass = validateLetterAndNumber(value);
      if (!pass && value) {
        return Promise.reject(errorMessage);
      }
      return Promise.resolve();
    },
  };

  return result;
}
/**
 * 验证器 字符串只含数字
 * @param {string} errorMessage
 */
export function numberValidator(errorMessage: string = '只允许输入数字'): Rule {
  const result: Rule = {
    validator: (rule, value) => {
      if (value && !/^[0-9]+$/.test(value)) {
        return Promise.reject(errorMessage);
      }
      return Promise.resolve();
    },
  };

  return result;
}

/**
 * 验证器 ip是否正确
 * @param {*} errorMessage
 */
export function ipValidator(errorMessage: string = 'ip格式有误'): Rule {
  const result: Rule = {
    validator: (rule, value) => {
      const pass = validateIp(value);
      if (!pass && value) {
        return Promise.reject(errorMessage);
      }
      return Promise.resolve();
    },
  };

  return result;
}

/**
 * 验证器 手机号是否正确
 * @param {*} errorMessage
 */
export function mobileValidator(errorMessage: string = '手机号格式有误'): Rule {
  const result: Rule = {
    validator: (rule, value) => {
      const pass = validateMobile(value);
      if (!pass && value) {
        return Promise.reject(errorMessage);
      }
      return Promise.resolve();
    },
  };

  return result;
}